<template>
    <div class="entry-warpper">
        <span class="add-icon"></span><span class="title"><slot></slot></span>
    </div>
</template>

<script>
export default {
    name: 'SubmitInfoEntry',
    data () {
        return {}
    }
}
</script>

<style lang='less' scoped>
.entry-warpper {
    display: flex;
    align-items: center;
    padding: 36px 20px;
    border: 1px solid #F1F1F1;
    border-radius: 4px;

    .add-icon {
        display: block;
        width: 15px;
        height: 15px;
        background: url('../img/add-icon.png') no-repeat center center;
        background-size: cover;
        margin-right: 10px;
        position: relative;
        top: -2px;
    }

    .title {
        font-size: 18px;
        color: #333;
        line-height: 1;
        font-weight: 600;
    }
}
</style>
