
import { mmcAjax } from '@/common/js/mmcModule';
const baseurl = 'https://api.jisuchou.net/';

/**
 * 提现-提现校验
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93455493
 * @param {projuuid} 项目uuid
 *
*/
const getWithdrawInfo = function (projuuid) {
    const url = `${baseurl}mmc/withdrawals/check/${projuuid}`;
    const debuger = false;
    if (debuger) {
        const project = require('./mock/info2.json');
        return new Promise((resolve, reject) => {
            resolve(project)
        })
    }
    return mmcAjax({
        method: 'GET',
        url
    })
}

/**
 * 提现-提现预提交
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93455501
 * @param {projuuid} 项目uuid
 *
*/
const getWithdrawPreSubmit = function (projuuid) {
    const url = `${baseurl}mmc/withdrawals/preSubmit/${projuuid}`;
    const debuger = false;
    if (debuger) {
        const project = require('./mock/preSubmit.json');
        return new Promise((resolve, reject) => {
            resolve(project)
        })
    }
    return mmcAjax({
        method: 'GET',
        url
    });
}

/**
 * 提现-提现预提交
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93455501
 * @param {projuuid} 项目uuid
 *
*/
const postWithdrawSubmit = function (params) {
    const url = `${baseurl}mmc/withdrawals/submit`;
    const debuger = false;
    if (debuger) {
        const project = require('./mock/preSubmit.json');
        return new Promise((resolve, reject) => {
            resolve(project)
        })
    }
    return mmcAjax({
        method: 'POST',
        url,
        data: {
            project_uuid: params.projuuid,
            withdraw_token: params.withdraw_token
        },
    });
}

export {
    getWithdrawInfo,
    getWithdrawPreSubmit,
    postWithdrawSubmit,
}
