<template>
    <div>
        <div class="footer-warpper">
            <span class="tips"
                v-if="hasDoingWithdrawals">您有一条提现申请处理中，处理完成后方可再次提现
            </span>
            <button
                v-if="hasDoingWithdrawals"
                @click="toWithdrawProgress"
                class="default-btn active">
                查看提现进度
            </button>

            <button v-else
                class="default-btn"
                :class="{ active: cashWithdrawState }"
                @click="preWithdraw">点击申请提现
            </button>
        </div>

        <ComfirmModal
            v-if="showConfirmModal && preWithdrawInfo"
            :preWithdrawInfo="preWithdrawInfo"
            @changeConfirmModal="changeConfirmModal"
        />
    </div>
</template>

<script>
import ComfirmModal from './ConfirmModal';
import { getWithdrawPreSubmit } from '../api';

export default {
    name: 'FooterBar',
    data() {
        return {
            showConfirmModal: false,
            preWithdrawInfo: null,
        }
    },
    props: {
        cashWithdrawState: {
            type: Boolean,
        },
        withdrawInfo: {
            type: Object,
        }
    },
    components: {
        ComfirmModal
    },
    computed: {
        hasDoingWithdrawals() {
            return this.withdrawInfo.has_doing_withdrawals;
        },
    },
    methods: {
        toWithdrawProgress() {
            location.href = `${window.BasePath}fund/withdraw/timeline?withdrawals_id=${this.withdrawInfo.withdraw_id}&projuuid=${this.projuuid}`;
        },

        preWithdraw() {
            if (!this.cashWithdrawState) return;

            const projuuid = this.projuuid;
            showLoading('提现中...');
            if (this.isSubmitPreWithdraw) return false;
            this.isSubmitPreWithdraw = true;

            const query = this.$route.query;
            getWithdrawPreSubmit(query.projuuid)
                .then(res => {
                    this.isSubmitPreWithdraw = false;
                    hideLoading();
                    console.log(res, '==== PreWithdraw ====');

                    const data = res.data;
                    if(res.code == 0 && data && !data.withdraw_ok) {
                        alertMsg(data.reason_info);
                    } else if (res.code === 0 && data && data.withdraw_ok) {
                        this.preWithdrawInfo = data.withdraw_info;
                        this.showConfirmModal = true;
                    } else {
                        alertMsg(res.msg);
                    }
                })
                .catch(err => {
                    hideLoading();
                    this.isSubmitPreWithdraw = false;
                })
        },

        changeConfirmModal() {
            this.showConfirmModal = false;
        }
    }
}
</script>

<style lang='less' scoped>
.footer-warpper {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 10;

    .tips {
        width: 100%;
        display: block;
        font-size: 13px;
        color: #FF434B;
        text-align: center;
        line-height: 1;
        padding: 7px 0;
        background: #FFEEEE;
    }

    .default-btn {
        display: block;
        width: 100%;
        height: 100%;
        background: #EFEFEF;
        line-height: 1;
        font-size: 18px;
        color: #251a1a;
        text-align: center;
        line-height: 1;
        border: none;
        padding: 15px 0;
        color: #999;
        pointer-events: none;
    }

    .default-btn.active {
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
        color: #FFFFFF;
        pointer-events: auto;
    }
}
</style>
