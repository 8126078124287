<template>
    <div class="confirm-modal" v-if="preWithdrawInfo">
        <div class="modal-body">
            <div class="cell-header">
                <h2 class="title"> 确认提现 </h2>
            </div>

            <div class="cell-body">
                <ul class="info-content">
                    <li class="item">收款人姓名：{{ preWithdrawInfo.payee_name }}</li>
                    <li class="item user-account">账号：{{ preWithdrawInfo.payee_account_info }}</li>

                    <li class="item">提现金额：{{ preWithdrawInfo.withdraw_amount | toDecimal2 }} 元</li>
                    <li class="item">手续费：{{ preWithdrawInfo.fee_amount | toDecimal2 }} 元（{{ preWithdrawInfo.fee_desc }}）</li>
                    <li class="item">实际到账：{{ preWithdrawInfo.reality_amount | toDecimal2 }} 元</li>
                </ul>
            </div>

            <div class="cell-footer">
                <button class="btn" @click="closeConfirmModal">取消</button>
                <button class="btn submit-btn" @click="confirmSubmit">确认提现</button>
            </div>

        </div>
    </div>
</template>

<script>
import { postWithdrawSubmit } from '../api';

export default {
    name:'ConfirmModal',
    data () {
        return {
            isSubmitWithdraw: false,
        }
    },
    props: {
        preWithdrawInfo: {
            type: Object,
        }
    },
    filters: {
        toDecimal2(value) {
            let amount = parseFloat(value)
            if (isNaN(amount)) return '';

            let f = Number(amount)/100;
            let s = f.toString();
            let rs = s.indexOf('.');
            if (rs < 0) {
                rs = s.length;
                s += '.';
            }
            while (s.length <= rs + 2) {
                s += '0';
            }
            return s;
        }
    },
    methods: {
        closeConfirmModal() {
            // type为 0 关闭弹窗
            this.$emit('changeConfirmModal');
        },

        confirmSubmit() {
            const { projuuid } = this.$route.query;

            showLoading('正在提交...');
            if (this.isSubmitWithdraw) return false;
            this.isSubmitWithdraw = true;

            const params = {
                projuuid,
                withdraw_token: this.preWithdrawInfo.withdraw_token
            }
            postWithdrawSubmit(params)
                .then(res => {
                    this.isSubmitWithdraw = false;
                    hideLoading();

                    if (res.code === 0) {
                        const withdrawalsId = res.data.withdraw_id;
                        this.$emit('changeConfirmModal');
                        location.href = `${window.BasePath}fund/withdraw/applied?withdrawals_id=${withdrawalsId}&projuuid=${projuuid}`;
                    } else {
                        alertMsg(res.msg);
                    }
                })
                .catch(err => {
                    hideLoading();
                    this.isSubmitWithdraw = false;
                })
        }
    }
}
</script>

<style lang='less' scoped>
.confirm-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background: rgba(0, 0, 0, 0.7);

    .modal-body {
        width: 270px;
        background: #fff;
        border-radius: 12px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        overflow: hidden;
    }
    .cell-header {
        text-align: center;
        padding: 18px 20px 15px;
    }
    .cell-body {
        padding:0 20px;
        margin-bottom: 24px;
    }
    .info-content {
        li {
            font-size: 13px;
            color: #030303;
            line-height: 18px;
            text-align: left;
        }
    }
    .user-account {
        margin-bottom: 10px;
    }
    .title {
        font-size: 17px;
        color: #030303;
        margin-bottom: 17px;
        margin: 0;
    }
    .desc {
        font-size: 13px;
        color: #030303;
    }

    .cell-footer {
        display: flex;
        justify-content: space-between;
        position: relative;
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            border-top: 1px solid #E9E9E9;
            transform: scaleY(0.5);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
        }
        .btn {
            width: 50%;
            display: block;
            font-size: 16px;
            color: #009BFF;
            text-align: center;
            line-height: 1;
            padding: 13px 0;
            border: none;
            background: #fff;
            position: relative;
        }
        .btn:first-child::after {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            border-right: 1px solid #E9E9E9;
            transform: scaleX(0.5);
            position: absolute;
            right: 0;
            top: 0;
        }
        .submit-btn {
            font-weight: 600;
        }
    }

}
</style>
