<template>
    <div class="withdraw-entry" v-if="withdrawInfo">
        <div class="project-balance">余额 {{ projectBalance }} 元</div>

        
        <div class="payee-container cell-info">
            <div v-if="!payee_info" @click="reviewPayee">
                <SubmitInfoEntry >点击填写收款人信息</SubmitInfoEntry>
            </div>
            <div v-else class="payee-info" :class="{error: payeeStateInfo.error_tips}">
                <div class="payee-user">
                    <div class="label">
                        <h1 class="title">收款人信息</h1>
                        <span v-if="payeeStateInfo.show_edit" class="edit-info" @click="toPayeeEidt">修改</span>
                    </div>


                    <ul class="info-content" v-if="payee_term==1">
                        <li class="item">收款人姓名: {{ payee_info.payee_name }}</li>
                        <li class="item">身份证号: {{ payee_info.card_no }}</li>
                        <li class="item">手机号码: {{ payee_info.phone }}</li>
                        <li class="item">收款方式: 个人</li>

                    </ul>
                    <ul class="info-content" v-if="payee_term==2">
                        
                        <li class="item">收款方式: 对公</li>

                    </ul>
                </div>

                <div class="payee-bank">
                    
                    <ul class="info-content" v-if="payee_term==1">
                        <!-- <li class="item">收款人姓名：{{ payee_info.payee_name }}</li> -->
                        <li class="item">开户银行: {{ payee_info.payee_location_name }}</li>
                        <li class="item">银行卡号: {{ payee_info.payee_account }}</li>
                        <li class="item">开户支行: {{ payee_info.payee_location_branch }}</li>
                    </ul>
                    <ul class="info-content" v-if="payee_term==2">
                        <li class="item">对公账户开户名称: {{ payee_info.payee_name }}</li>
                        <li class="item">开户银行: {{ payee_info.payee_location_name }}</li>
                        <li class="item">银行卡号: {{ payee_info.payee_account }}</li>
                        <li class="item">开户支行: {{ payee_info.payee_location_branch }}</li>
                    </ul>
                </div>
                <span class="tips" v-if="payeeStateInfo.error_tips && payee_info.reason">* {{payee_info.reason}}</span>
            </div>
        </div>

        <FooterBar
            :cashWithdrawState="cashWithdrawState"
            :withdrawInfo='withdrawInfo'/>
    </div>
</template>

<script>
import SubmitInfoEntry from './components/SubmitInfoEntry';
import FooterBar from './components/FooterBar';
import { getWithdrawInfo } from './api';
import { authChecker, shareCtrl } from '@/common/js/mmcModule';

export default {
    name:'WithdrawEntry',
    data () {
        return {
            projuuid: '',
            
            payee_info: '',
            has_doing_withdrawals: false,
            withdrawInfo: null,
            payee_term:0,
        }
    },

    components: {
        SubmitInfoEntry,
        FooterBar,
    },

    computed: {
        

        payeeStateInfo() {
            if (!this.payee_info) return false;
            return this.getStateInfo(this.payee_info.state);
        },

        projectBalance() {
            const withdrawInfo = this.withdrawInfo
            if (withdrawInfo) {
                return withdrawInfo.balance > 0 ? withdrawInfo.balance/100 : withdrawInfo.balance;
            }
        },

        cashWithdrawState() {
            const { payee_info, has_doing_withdrawals } = this.withdrawInfo;
            if (has_doing_withdrawals) return false;
            
            if (!payee_info) return false;
            if (payee_info.state === 2 || payee_info.state === 4 || payee_info.state === 5) return false;
            
            return true;
        },

        relationType() {
            const type = this.payee_info.type;
            let relation = '本人';
            switch(type) {
                case 'family':
                    relation = '直系亲属';
                    break;
                case 'couple':
                    relation = '夫妻';
                    break;
                case 'inpatient':
                    relation = '医院';
                    break;
                default:
                    relation;
                    break;
            }
            return relation;
        }
    },

    created() {},

    mounted() {
        authChecker.check({ notneedphone: 1 }, () => {
            const { projuuid } = this.$route.query;
            this.projuuid = projuuid;

            this.getWithdrawCheckInfo(projuuid);
            shareCtrl.init();
        });
    },

    methods: {
        getWithdrawCheckInfo(projuuid) {
            getWithdrawInfo(projuuid)
            .then(res => {
                if (res.code == 0 ) {
                    const { has_doing_withdrawals, payee_info } = res.data;
                    this.has_doing_withdrawals = has_doing_withdrawals;
                    
                    this.payee_info = payee_info;
                    this.withdrawInfo = res.data;
                    if(payee_info){
                        this.payee_term = payee_info.payee_term
                        // this.payee_term = 2
                    }
                    
                }
            })
        },

        

        toPayeeEidt() {
            const url = '/fund/review/payee';
            this.routerPath(url);
        },

        reviewAided() {
            const projuuid = this.projuuid;
            this.$router.push({
                path: '/fund/review/aided',
                query: { projuuid }
            })
        },

        reviewPayee() {
            // if (!this.patient_info) {
            //     alertMsg('请填写患者信息');
            //     return;
            // }

            const projuuid = this.projuuid;
            this.$router.push({
                path: '/fund/review/payee',
                query: { projuuid }
            })
        },

        routerPath(url) {
            const projuuid = this.projuuid;
            location.href = `https://m.jisuchou.net${url}?projuuid=${projuuid}&edit=1`;
        },

        // 获取审核状态信息
        getStateInfo(state) {
            let show_edit = false;
            let error_tips = false;
            let hasWithdrawals = this.has_doing_withdrawals;
            if (hasWithdrawals) {
                return { show_edit, error_tips };
            }
            if (state === 0 || state === 1 || state === 2 || state === 4 || state === 5) {
                show_edit = true;
            }
            if (state === 2 || state === 4 || state === 5) {
                error_tips = true;
            }
            return { show_edit, error_tips };
        },
    }
}
</script>

<style lang='less' scoped>
.withdraw-entry {
    padding: 35px 20px 85px;

    .project-balance {
        margin-bottom: 35px;
        font-size: 34px;
        color: #333;
        font-weight: 600;
        line-height:1;
        text-align: left;
    }
    .info-content {
        li {
            font-size: 13px;
            color: #333333;
            line-height: 23px;
        }
    }
}

.patient-info,
.payee-info {
    text-align: left;
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    padding: 18px 20px;

    .label {
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    .title {
        color: #333333;
        font-size: 16px;
        margin: 0;
    }
    .edit-info {
        color: #009BFF;
        padding-left: 22px;
        position: relative;
    }
    .edit-info::before {
        content: "";
        display: block;
        width: 14px;
        height: 16px;
        background: url('./img/edit-icon.png') no-repeat center;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.error {
    border: 1px solid #FF434B;
    position: relative;
    .tips {
        display: block;
        font-size: 12px;
        color: #FF434B;
        font-weight: 600;
        position: relative;
        bottom: -5px;
    }
}

.payee-user {
    margin-bottom: 15px;
    padding-bottom: 15px;
    position: relative;
    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        transform: scaleY(0.5);
        opacity: 0.27;
        border-bottom: 1px solid #979797;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.patient-container {
    margin-bottom: 20px;
}
</style>
